.laurel-carousel {
  height: 300px;
  width: 300px;
  margin: 0 auto;
  margin-top: 1rem;
  border-radius: 50%;
  
}

.laurel-carousel-fade {
  height: 300px;
  width: 300px;
  margin: 0 auto;
  margin-top: 1rem;
  border-radius: 50%;
}
 
.laurel-carousel img {
  height: 300px;
  width: 300px;
  margin-bottom: 2rem;
  margin-top: 1rem;
  border-radius: 50%;
  /* border: 3px solid rgb(199, 179, 179); */
}


.home-text {
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.1rem;
  max-width: 500px;
  margin: 0 auto;
  margin-top: -2rem;
  margin-bottom: 2rem;
}

.home-links {
  color: inherit; 
  text-decoration: underline;
   
}

.home-links:hover {
  color: #742121;
  transition: 0.3s;
}

/* If the page is viewed from a mobile device,
    add margins to the paragraphs to prevent their
    content from touching the edges of the screen */
@media only screen and (max-width: 600px) {
  .home-text {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}