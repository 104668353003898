.bookshelf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: inherit;
  text-align: center;
}

.bookshelf-list-item-desc {
  font-size: 1.1rem;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 2rem;

}

.bookshelf-list-item-title a {
  color: inherit; 
  text-decoration: underline;
  margin: 0 auto;
  
}

.bookshelf-list-item-title a:hover {
  color: #a05858;
  transition: 0.3s;
}

/* If the page is viewed from a mobile device,
    add margins to the paragraphs to prevent their
    content from touching the edges of the screen */
@media only screen and (max-width: 600px) {
  .bookshelf-list-item-desc {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .bookshelf-list-item-title {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .bookshelf-desc {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}