.header-carousel {
  width: 100%;
  height: auto;
  max-height: 300px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: none;
}

.carousel-indicators {
  display: none;
}

.header-carousel::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 80%;
  background: linear-gradient(to bottom, transparent, #380404);
  z-index: 1;
}

.carousel img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
}
