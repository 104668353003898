.links-container {
  position: fixed;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  font-family: 'Lora', serif;
  font-weight: 500;
  font-size: 1.2rem;
  color: rgb(199, 179, 179);
  margin: 0 10px;
  text-decoration: none;
}

.link:hover {
  color: #a05858;
}

.music-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: inherit;
  text-align: center;
}

.music-container h2 {
  margin-top: 1rem;
}

.music-container p {
  margin: 1rem;
}

.music-container music-playlist {
  margin: 0 auto;
  max-width: 500px;
}

.music-playlist {
  margin: 0 auto;
  max-width: 500px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.music-link-desc {
  font-size: 1.1rem;
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 0.5rem;
}