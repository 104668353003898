.page-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.contact-link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  color: inherit;
  text-align: center;
}

.contact-link:hover {
  color: #a05858;
  transition: 0.3s;
}