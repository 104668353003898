.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background-color: none;
  position: relative;
  flex-direction: row;
  
}

.navigation-home {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background-color: none;
  position: relative; 
}

.nav-link {
  margin-left: 2rem; 
  margin-bottom: 16px; 
}

.nav-link2 {
  margin-left: 2rem; 
  margin-bottom: 16px; 
  color: inherit;
  text-decoration: none;
}

.nav-link:hover, .nav-link2:hover {
  color: #a05858;
}

.nav-links {
  display: flex;
  flex-direction: row;
  padding: 16px;
}

.nav-links-row1, .nav-links-row2 {
  display: flex;
  flex-direction: row;
}

.nav-link, .nav-link2 {
  margin-left: 16px;
  padding: 1px;
  background-color: none;
  /* color: inherit; */
}



@media screen and (max-width: 600px) {
  .navigation-container {
    align-items: center;
  }

  .nav-links {
    flex-direction: column;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    padding: 0;
    background-color: none;
    display: flex !important; 
    justify-content: center;
    align-items: center;
  }

  .navigation {
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: none;
    position: relative;
    display: row;  
  }

  .nav-link {
    margin: 5px;
    padding: 1px;
    background-color: none;
    flex-direction: row;
  }

  .nav-link2 {
    margin: 5px;
    padding: 1px;
    background-color: none;
    flex-direction: row;
    color: inherit;
    text-decoration: none;
  }
}
