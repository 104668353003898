.page-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  margin-top: -1rem;
  text-align: center;
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: 20px auto;
  margin-left: 10%;
  margin-right: 10%;
}

.gallery-item {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
}

.gallery-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
}

.gallery-item:hover img {
  opacity: 0.8;
}

.gallery-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #cfb5b5;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.gallery-item:hover .gallery-overlay {
  opacity: 1;
}

.gallery-modal-button {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border: none;
  color: #cfb5b5;
  background-color: transparent;
  font-size: 1rem;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

.gallery-modal-button:hover {
  opacity: 0.5;
  background-color: transparent;
}

.gallery-modal-button:focus,
.gallery-modal-button:active {
  outline: none;
  background-color: transparent;
}

.gallery-modal-button:not(:disabled):not(.disabled):active {
  background-color: transparent;
}

.gallery-modal-close-button {
  color: #cfb5b5;
  background-color: #410f0f;
}

.gallery-modal-close-button:hover {
  color: #cfb5b5;
  background-color: #1a0e0e;
}

.gallery-desc {
  font-size: 1.05rem;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 0.5rem;
  justify-content: center;
  text-align: center;
  color: #410f0f;
}

.modal-title {
  font-size: 1.5rem;
  text-align: center;
  color: #410f0f;
}

.modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* media query for mobile devices */

@media only screen and (max-width: 600px) {
  .gallery-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-color: #cfb5b5;
  color: #410f0f;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 999;
}


.back-to-top.visible {
  opacity: 0.5;
}

.back-to-top:active,
.back-to-top:focus,
.back-to-top:active:focus {
  background-color: #fff;
  color: #000;
  box-shadow: none;
  border-color: transparent;
}



.back-to-top.fade-out {
  opacity: 0;
}

.back-to-top:hover {
  opacity: 0.5;
}

.arrow-up {
  width: 20px;
  height: 20px;

  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}